import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb'
import {ImageDropzone} from './imageDropzone'
import { api } from '../../../../api'

import {
  Heading,
  Flex,
  Textarea,
  Input,
  Box,
  Button
} from '@chakra-ui/react'
import { FileWithPath } from 'react-dropzone'

export interface bannerInterface {
  id: number;
  title: string;
  image: string;
  description: string;
}

function Banner() {
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [description, setdescription] = useState('')
  const [imageBase64, setImageBase64] = useState('');

  const handleTitleChange = (event: any) => {
    setTitle(event.target.value)
  }

  const handleEditorChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setdescription(event.target.value)
  }
  const handleImageSelected = async (selected: FileWithPath | null) => {
    if (selected) {
      const base64Image = await convertBase64(selected);
      setIsImageSelected(true);
      setImageBase64(base64Image); 
      //console.log('base64Image', base64Image)
    }
  };
  
  const convertBase64 = async (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
  
      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };
  
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  
  const inputBanner = async (title: string, base64Image: string, description: string) => {
    const postBanner = '/api/cms/content/createBanner'
  
    const data = {
      title: title,
      image: base64Image,
      description: description,
    };
  
    api
      .post(postBanner, data)
      .then((response) => {
        console.log('Dado enviado:', response.data);
        console.log('imageBase64:', base64Image);
      })
      .catch((error) => {
        console.error('Erro ao enviar o Banner:', error);
      });
  };
  
  
  const handleSubmit = () => {
    inputBanner(title, imageBase64, description);
    // console.log(result.data.)
  }  

  return (
    <Flex h="100%" p="40px" bgColor="#fafafa" direction="column">
      <SettingsBreadcrumb />
      <Heading fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%" mt={3}>
        Criar Banner
      </Heading>

      <Heading
        pt="24px"
        pb="32px"
        fontWeight="600"
        fontSize="20px"
        color="#1664D7"
      >
        {' '}
        Adicione um Título
      </Heading>
      <Input
        type="text"
        mb={30}
        value={title.toUpperCase()}
        placeholder={'Digite o Título'}
        maxLength={50}
        onChange={handleTitleChange}
      />
      <Box>
        
      <ImageDropzone 
          onImageSelected={(selected: FileWithPath | null) => handleImageSelected(selected)} base64={''}      />
      </Box>
      <Heading
        pt="24px"
        pb="32px"
        fontWeight="600"
        fontSize="20px"
        color="#1664D7"
      >
        {' '}
        Adicione uma descrição da imagem
      </Heading>
      <Textarea
        height={100}
        value={description}
        onChange={handleEditorChange}
        required
      ></Textarea>
      <Flex mt="35px" justifyContent="end">
        <Button type="submit" m="6px" onClick={() => navigate(-1)}>
          Cancelar
        </Button>
        <Button
          type="submit"
          m="6px"
          color="#FFF"
          bgColor="#1665D8"
          _hover={{ opacity: 0.8 }}
          onClick={() => {
            handleSubmit()
            navigate('/cms/content')
          }}
          disabled={!description || isImageSelected==false}
        >
          Salvar
        </Button>
      </Flex>
    </Flex>
  )
}

export default Banner
