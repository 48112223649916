import { FaUsers } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { Flex, Heading, Grid, GridItem } from '@chakra-ui/react'

import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb'
import { SettingsCard } from '../../../../components/SettingsCard'
import withAuth from '../../../../contexts/AuthContext/withAuth'

const NewUser = () => {
  const navigate = useNavigate()
  return (
    <>
      <Flex p="40px" pr="0" bgColor="#fafafa" direction="column" w="100%">
        <SettingsBreadcrumb />
        <Heading
          my="44px"
          fontWeight="600"
          fontSize="21px"
          color="#6F6F6F"
          w="100%"
        >
          Criar Novo Usuario
        </Heading>

        {/* Search bar */}
        <Grid templateColumns="3fr 3fr 3fr 3fr" w="100%" gap={5}>
          <GridItem>
            <SettingsCard
              buttonText="Criar"
              onClick={() => navigate('/cms/users/create')}
              icon={FaUsers}
              cardTitle="Auditor ou Admnistrador"
            />
          </GridItem>
        </Grid>

        {/* Table */}
      </Flex>
    </>
  )
}

export default withAuth(NewUser)
