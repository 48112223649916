import { Flex, Heading, Button, Grid, GridItem, Input, Box } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb'
import { api } from '../../../../api'

interface DataItem {
  pageName: string;
  situation: string | number;
  register: string | number
  id: number;
}

interface PageData {
  updateStatus: (pageName: string, newStatus: string) => void;
  id: number;
  pageName: string;
  action: JSX.Element;
  situation: number;
  data: PageData[];
  filter: string;
}

function Add() {
  const datatables: DataItem[] = [];
  const [pages, setPages] = useState('')
  const [originalData, setOriginalData] = useState<PageData[]>([]);
  const navigate = useNavigate()

  const getTablePages = (datatable: DataItem[]) => {
    const data = {
      datatable: datatable.map(function (e: DataItem) {
        return {
          id: e.id,
          pageName: e.pageName,
          register: e.register === 'Não' ? 0 : 1,
          situation: e.situation === 'Ativo' ? 1 : 0,
        };
      }),
    };
  
    const postAdd = '/api/cms/manage/add';
    api.post(postAdd, data)
      .then(response => {
        //console.log('Dado enviado:', response.data);
        setOriginalData(response.data);
      })
      .catch(error => {
        console.error('Erro ao enviar os dados:', error);
      });
  };
  
  const handleChange = (e: any) => {
    setPages(e.target.value)
  }

  const handleSubmit = () => {
    if (pages) {
      const newItem: DataItem = {
        pageName: pages,
        situation: 'Ativo',
        register: 0,
        id: 1
      };

      //salva
      getTablePages([newItem]);

      const isExistingItem = datatables.some(item => item.pageName === pages);
      if (isExistingItem) {
        console.error('Nome da página já existe.');
        return;
      }
    }
  };

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="44px"
        mb="20px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Incluir Nova Página
      </Heading>
      <Grid w="100%" gridTemplateColumns="4fr 4fr 4fr" gap="35px">
        <GridItem>
          <form>
            <Input
              type="text"
              value={pages}
              placeholder="Insira o nome da página"
              required
              onChange={handleChange}
              maxLength={40}
              minLength={4}
            />
          </form>
          <br />
          <Box alignItems={'flex-end'}>
            <Button type="submit" m="px" onClick={() => navigate(-1)}>
              Desistir
            </Button>
            <Button
              type="submit"
              m="6px"
              color="#FFF"
              bgColor="#1665D8"
              _hover={{ opacity: 0.8 }}
              onClick={() => {
                handleSubmit();
                navigate(-1);
              }}
              disabled={pages.length < 4}
            >
              Salvar
            </Button>
          </Box>
        </GridItem>
      </Grid>
    </Flex>
  )
}

export default Add
