import { Flex, Heading } from '@chakra-ui/react'

import SettingsBreadcrumb from '../../components/SettingsBreadcrumb'
import withAuth from '../../contexts/AuthContext/withAuth'
import GridSettings from './components/GridSettings'

/*PROBLEMA
  Renderizar o componente correto e atualizar o BreadCrumb crum de acordo com a rota de configuraçao 
  se centralizar demais pode chegar a dar problema de efeitos colaterais toda vez que acrescentar uma nova configuraçao
  */

const Settings = () => {
  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      {/* <SettingsBreadcrumb /> */}
      <Heading
        mt="44px"
        mb="20px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Configurações
      </Heading>
      <GridSettings />
    </Flex>
  )
}

export default withAuth(Settings)
