import { BiUserCheck } from 'react-icons/bi'

import {
  Flex,
  Icon,
  Modal,
  ModalContent,
  ModalFooter,
  Button,
  ModalOverlay,
  Text,
  ModalBody,
  Box
} from '@chakra-ui/react'

export interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen?: () => void
}

function ModalConfirmNewUser({ isOpen, onClose }: Props) {
  return (
    <Modal size="xs" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="12px 16px">
        <ModalBody
          height="100px"
          flexDir="column"
          display="flex"
          alignItems="center"
        >
          <Icon boxSize={24} color="#1665D8" as={BiUserCheck} />

          <Text color="black">Usuario Incluido com sucesso</Text>
        </ModalBody>
        <ModalFooter alignContent="center">
          <Flex alignContent="center" justifyContent="center" w="100%">
            <Button
              color="#FFF"
              bgColor="#1665D8"
              _hover={{ opacity: 0.8 }}
              onClick={onClose}
            >
              OK
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalConfirmNewUser
