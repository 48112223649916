import { IconProps, Icon } from '@chakra-ui/react'
const Calendar = (props: IconProps) => (
  <Icon viewBox="0 0 26.243 26.144" {...props}>
    <g
      id="Grupo_23224"
      data-name="Grupo 23224"
      transform="translate(-22.9 -651.896)"
    >
      <g
        id="Grupo_23220"
        data-name="Grupo 23220"
        transform="translate(23 653.117)"
      >
        <path
          id="Caminho_68064"
          data-name="Caminho 68064"
          d="M143.609,30.844H121.634a2.032,2.032,0,0,0-2.034,2.024V53.642a2.032,2.032,0,0,0,2.034,2.024h18.719a.408.408,0,0,0,.287-.119l4.883-4.883a.406.406,0,0,0,.119-.288V32.868A2.032,2.032,0,0,0,143.609,30.844Zm1.22,19.363-4.645,4.645h-18.55a1.217,1.217,0,0,1-1.221-1.21V32.868a1.217,1.217,0,0,1,1.221-1.211h21.975a1.217,1.217,0,0,1,1.22,1.211Z"
          transform="translate(-119.6 -30.844)"
          fill={props.stroke as string}
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.2}
        />
        <path
          id="Caminho_68065"
          data-name="Caminho 68065"
          d="M148.471,53.02h-3.488a1.8,1.8,0,0,0-1.8,1.792v3.465a.407.407,0,1,0,.813,0V54.812a.984.984,0,0,1,.988-.977h3.488a.407.407,0,1,0,0-.814Z"
          transform="translate(-123.242 -34.269)"
          fill={props.stroke as string}
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.2}
        />
      </g>
      <path
        id="Caminho_68066"
        data-name="Caminho 68066"
        d="M145.237,37.581h-25.23a.407.407,0,1,0,0,.813h25.23a.407.407,0,0,0,0-.813Z"
        transform="translate(-96.6 621.233)"
        fill={props.stroke as string}
      />
      <g
        id="Grupo_23221"
        data-name="Grupo 23221"
        transform="translate(28.29 655.151)"
      >
        <path
          id="Caminho_68067"
          data-name="Caminho 68067"
          d="M142.477,33.25a1.221,1.221,0,1,0,1.221,1.22A1.221,1.221,0,0,0,142.477,33.25Z"
          transform="translate(-128.234 -33.25)"
          fill={props.stroke as string}
        />
        <path
          id="Caminho_68068"
          data-name="Caminho 68068"
          d="M134.777,33.25A1.221,1.221,0,1,0,136,34.47,1.22,1.22,0,0,0,134.777,33.25Z"
          transform="translate(-127.045 -33.25)"
          fill={props.stroke as string}
        />
        <path
          id="Caminho_68069"
          data-name="Caminho 68069"
          d="M127.077,33.25a1.221,1.221,0,1,0,1.221,1.22A1.221,1.221,0,0,0,127.077,33.25Z"
          transform="translate(-125.856 -33.25)"
          fill={props.stroke as string}
        />
      </g>
      <g
        id="Grupo_23222"
        data-name="Grupo 23222"
        transform="translate(29.103 651.896)"
      >
        <path
          id="Caminho_68070"
          data-name="Caminho 68070"
          d="M127.226,29.4a.407.407,0,0,0-.408.407v4.476a.407.407,0,0,0,.814,0V29.807A.406.406,0,0,0,127.226,29.4Z"
          transform="translate(-126.818 -29.4)"
          fill={props.stroke as string}
        />
        <path
          id="Caminho_68071"
          data-name="Caminho 68071"
          d="M134.926,29.4a.406.406,0,0,0-.407.407v4.476a.407.407,0,1,0,.813,0V29.807A.406.406,0,0,0,134.926,29.4Z"
          transform="translate(-128.007 -29.4)"
          fill={props.stroke as string}
        />
        <path
          id="Caminho_68072"
          data-name="Caminho 68072"
          d="M142.626,29.4a.406.406,0,0,0-.407.407v4.476a.407.407,0,0,0,.814,0V29.807A.407.407,0,0,0,142.626,29.4Z"
          transform="translate(-129.196 -29.4)"
          fill={props.stroke as string}
        />
      </g>
      <g
        id="Grupo_23223"
        data-name="Grupo 23223"
        transform="translate(25.441 661.256)"
      >
        <path
          id="Caminho_68073"
          data-name="Caminho 68073"
          d="M126.149,46.725h-3.255a.407.407,0,0,0-.408.407V49.98a.407.407,0,0,0,.408.407h3.255a.407.407,0,0,0,.408-.407V47.132A.407.407,0,0,0,126.149,46.725Zm-.406,2.849H123.3V47.539h2.442Z"
          transform="translate(-122.487 -41.435)"
          fill={props.stroke as string}
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.2}
        />
        <path
          id="Caminho_68074"
          data-name="Caminho 68074"
          d="M132.888,46.725h-3.256a.406.406,0,0,0-.407.407V49.98a.406.406,0,0,0,.407.407h3.256a.406.406,0,0,0,.406-.407V47.132A.406.406,0,0,0,132.888,46.725Zm-.408,2.849h-2.442V47.539h2.442Z"
          transform="translate(-123.528 -41.435)"
          fill={props.stroke as string}
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.2}
        />
        <path
          id="Caminho_68075"
          data-name="Caminho 68075"
          d="M139.625,46.725h-3.256a.406.406,0,0,0-.406.407V49.98a.406.406,0,0,0,.406.407h3.256a.406.406,0,0,0,.407-.407V47.132A.406.406,0,0,0,139.625,46.725Zm-.408,2.849h-2.441V47.539h2.441Z"
          transform="translate(-124.568 -41.435)"
          fill={props.stroke as string}
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.2}
        />
        <path
          id="Caminho_68076"
          data-name="Caminho 68076"
          d="M146.362,46.725h-3.255a.407.407,0,0,0-.408.407V49.98a.407.407,0,0,0,.408.407h3.255a.407.407,0,0,0,.408-.407V47.132A.407.407,0,0,0,146.362,46.725Zm-.407,2.849h-2.442V47.539h2.442Z"
          transform="translate(-125.609 -41.435)"
          fill={props.stroke as string}
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.2}
        />
        <path
          id="Caminho_68077"
          data-name="Caminho 68077"
          d="M139.625,40.469h-3.256a.406.406,0,0,0-.406.407v2.848a.407.407,0,0,0,.406.408h3.256a.407.407,0,0,0,.407-.408V40.876A.406.406,0,0,0,139.625,40.469Zm-.408,2.848h-2.441V41.282h2.441Z"
          transform="translate(-124.568 -40.469)"
          fill={props.stroke as string}
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.2}
        />
        <path
          id="Caminho_68078"
          data-name="Caminho 68078"
          d="M132.888,40.469h-3.256a.406.406,0,0,0-.407.407v2.848a.407.407,0,0,0,.407.408h3.256a.407.407,0,0,0,.406-.408V40.876A.406.406,0,0,0,132.888,40.469Zm-.408,2.848h-2.442V41.282h2.442Z"
          transform="translate(-123.528 -40.469)"
          fill={props.stroke as string}
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.2}
        />
        <path
          id="Caminho_68079"
          data-name="Caminho 68079"
          d="M146.362,40.469h-3.255a.407.407,0,0,0-.408.407v2.848a.407.407,0,0,0,.408.408h3.255a.408.408,0,0,0,.408-.408V40.876A.407.407,0,0,0,146.362,40.469Zm-.407,2.848h-2.442V41.282h2.442Z"
          transform="translate(-125.609 -40.469)"
          fill={props.stroke as string}
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.2}
        />
        <path
          id="Caminho_68080"
          data-name="Caminho 68080"
          d="M126.149,52.981h-3.255a.407.407,0,0,0-.408.407v2.849a.408.408,0,0,0,.408.408h3.255a.407.407,0,0,0,.408-.408V53.388A.407.407,0,0,0,126.149,52.981Zm-.406,2.849H123.3V53.794h2.442Z"
          transform="translate(-122.487 -42.401)"
          fill={props.stroke as string}
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.2}
        />
        <path
          id="Caminho_68081"
          data-name="Caminho 68081"
          d="M132.888,52.981h-3.256a.406.406,0,0,0-.407.407v2.849a.407.407,0,0,0,.407.408h3.256a.407.407,0,0,0,.406-.408V53.388A.406.406,0,0,0,132.888,52.981Zm-.408,2.849h-2.442V53.794h2.442Z"
          transform="translate(-123.528 -42.401)"
          fill={props.stroke as string}
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.2}
        />
      </g>
    </g>
  </Icon>
)

export default Calendar
