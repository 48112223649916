import { useState, useRef, useEffect } from 'react'
import { useIMask } from 'react-imask'
import { useParams } from 'react-router-dom'

import {
  Flex,
  Heading,
  Box,
  Grid,
  Select,
  Button,
  Avatar,
  Input,
  FormLabel,
  FormControl
} from '@chakra-ui/react'

import { IUserForm } from '../_interfaces/IUserForm'
import { getUser, updateUser } from '../../../../api/users'
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb'
import ModalUpdateUser from '../ModalUpdateUser'

const initialValues = {
  cpf: {
    value: '',
    error: false
  },
  nome: {
    value: '',
    error: false
  },
  celular: {
    value: '',
    error: false
  },
  email: {
    value: '',
    error: false
  },
  perfil: {
    value: '',
    error: false
  }
}

function FormUpdateUser() {
  const { userId } = useParams()
  const [userIdValue, setUserIdValue] = useState('')
  const [formValues, setFormValues] = useState<IUserForm>(initialValues)
  const [selectedImage, setSelectedImage] = useState<any>()
  const [modalConfirmNewUser, setModalConfirmNewUser] = useState<boolean>(false)
  const inputReference = useRef<HTMLInputElement>(null)
  const [imageUrl, setImageUrl] = useState<any>()

  useEffect(() => {
    console.log('getUser', userId)
    if (userId) {
      loadData(userId)
      setUserIdValue(userId)
    }
  }, [userId])

  const loadData = async (userId: string) => {
    const { data } = await getUser(userId)
    const {
      dscCpf,
      nomUsuario,
      dscDddCelular,
      dscNumCelular,
      dscEmail,
      codPerfilAcesso
    } = data

    setFormValues({
      cpf: {
        value: dscCpf,
        error: false
      },
      email: {
        value: dscEmail,
        error: false
      },
      nome: {
        value: nomUsuario,
        error: false
      },
      celular: {
        value: `${dscDddCelular} ${dscNumCelular}`,
        error: false
      },
      perfil: {
        value: codPerfilAcesso,
        error: false
      }
    })
  }

  const handleFieldChange = (field: string, value: string) =>
    setFormValues({
      ...formValues,
      [field]: {
        error: false,
        value
      }
    })

  const { ref: cpfRef } = useIMask(
    { mask: '000.000.000-00' },
    {
      onAccept: (value) => handleFieldChange('cpf', value)
    }
  )

  const { ref: celularRef } = useIMask(
    { mask: '(00) 00000-0000' },
    { onAccept: (value) => handleFieldChange('celular', value) }
  )

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage))
    }
  }, [selectedImage])

  const handleFormSubmit = (e: any) => {
    e.preventDefault()
    const celValue = formValues.celular.value.split(' ')
    const params = {
      dscCpf: formValues.cpf.value,
      dscEmail: formValues.email.value,
      nomUsuario: formValues.nome.value,
      dscNumCelular: celValue[1],
      dscDddCelular: celValue[0],
      codPerfilAcesso: formValues.perfil.value
    }

    const user = updateUser(userIdValue, params)

    setModalConfirmNewUser(true)
    return user
  }

  const handleModalClose = () => {
    setModalConfirmNewUser(false)
  }

  return (
    <>
      <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
        <SettingsBreadcrumb />
        <Heading
          mt="44px"
          fontWeight="600"
          fontSize="21px"
          color="#6F6F6F"
          w="100%"
        >
          Incluir Usuario
        </Heading>

        {/* Search bar */}
        <Box m="40px 0" w="100%" pr="28px">
          <form onSubmit={handleFormSubmit}>
            <Grid gridTemplateColumns="4fr 8fr" gridGap="30px">
              <Box height="100px">
                <input
                  accept="image/*"
                  type="file"
                  id="select-image"
                  ref={inputReference}
                  style={{ display: 'none' }}
                  onChange={(e: any) => {
                    if (!e?.target?.files) {
                      return
                    }

                    setSelectedImage(e.currentTarget.files[0])
                  }}
                />

                {imageUrl && selectedImage ? (
                  <Avatar height="100px" w="100px" src={imageUrl} />
                ) : (
                  <Button
                    variant="contained"
                    border="1px solid #6F6F6F"
                    borderRadius="50%"
                    h="100%"
                    w="100px"
                    wordBreak="break-word"
                    whiteSpace="normal"
                    display="inline-block"
                    onClick={() => inputReference?.current?.click()}
                    p="5px"
                  >
                    Incluir Foto de Perfil
                  </Button>
                )}
              </Box>
              <Flex dir="column" height="80px">
                <FormControl>
                  <FormLabel>Perfil</FormLabel>
                  <Select
                    onChange={(e) =>
                      handleFieldChange('perfil', e.target.value)
                    }
                    value={formValues.perfil.value}
                    defaultValue="0"
                    w="50%"
                  >
                    <option value="0">Administrador</option>
                    <option value="1">Auditor</option>
                  </Select>
                </FormControl>
              </Flex>
              <Box height="80px">
                <FormControl>
                  <FormLabel mb="8px">CPF</FormLabel>
                  <Input
                    aria-label="cpf"
                    id="cpf"
                    required
                    arai-label="cpf"
                    ref={cpfRef}
                    value={formValues.cpf.value}
                    size="sm"
                  />
                </FormControl>
              </Box>
              <Box height="80px">
                <FormControl>
                  <FormLabel mb="8px">Nome Completo</FormLabel>
                  <Input
                    aria-label="nome"
                    value={formValues.nome.value}
                    onChange={(e) => handleFieldChange('nome', e.target.value)}
                    size="sm"
                  />
                </FormControl>
              </Box>
              <Box height="80px">
                <FormControl>
                  <FormLabel mb="8px">Celular</FormLabel>
                  <Input
                    aria-label="celular"
                    value={formValues.celular.value}
                    ref={celularRef}
                    size="sm"
                  />
                </FormControl>
              </Box>
              <Box height="80px">
                <FormControl>
                  <FormLabel mb="8px">E-mail</FormLabel>
                  <Input
                    aria-label="email"
                    required
                    value={formValues.email.value}
                    onChange={(e) => handleFieldChange('email', e.target.value)}
                    size="sm"
                  />
                </FormControl>
              </Box>
            </Grid>
            <Flex mt="35px" justifyContent="end">
              <Button type="submit" m="6px">
                Desistir
              </Button>
              <Button
                type="submit"
                m="6px"
                color="#FFF"
                bgColor="#1665D8"
                _hover={{ opacity: 0.8 }}
              >
                Incluir Usuario
              </Button>
            </Flex>
          </form>
        </Box>

        {/* Table */}
      </Flex>
      <ModalUpdateUser
        isOpen={modalConfirmNewUser}
        onClose={handleModalClose}
      />
    </>
  )
}

export default FormUpdateUser
