import { IconProps, Icon } from '@chakra-ui/react'
const Reports = (props: IconProps) => (
  <Icon viewBox="0 0 23.773 24.996" {...props}>
    <g
      id="Grupo_23030"
      data-name="Grupo 23030"
      transform="translate(-1068.35 -1168.35)"
    >
      <g
        id="Grupo_23016"
        data-name="Grupo 23016"
        transform="translate(1069 1169)"
      >
        <g id="Grupo_23014" data-name="Grupo 23014" transform="translate(0 0)">
          <path
            id="Caminho_67963"
            data-name="Caminho 67963"
            d="M3422.816,680.7v-5.733L3417.848,670h-11.083a.764.764,0,0,0-.764.764v22.167a.764.764,0,0,0,.764.764h11.084"
            transform="translate(-3406 -670)"
            fill="none"
            stroke={props.stroke as string}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.3}
          />
          <path
            id="Caminho_67964"
            data-name="Caminho 67964"
            d="M3534.969,703.911h-4.586a.382.382,0,0,1-.382-.382V702"
            transform="translate(-3518.152 -698.942)"
            fill="none"
            stroke={props.stroke as string}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.3}
          />
        </g>
        <g
          id="Grupo_23015"
          data-name="Grupo 23015"
          transform="translate(11.705 12.641)"
        >
          <circle
            id="Elipse_60"
            data-name="Elipse 60"
            cx={5.384}
            cy={5.384}
            r={5.384}
            transform="translate(0 0)"
            fill="none"
            stroke={props.stroke as string}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.3}
          />
          <path
            id="Caminho_67965"
            data-name="Caminho 67965"
            d="M3558,843.911l1.911,1.911,2.675-3.822"
            transform="translate(-3555.181 -838.207)"
            fill="none"
            stroke={props.stroke as string}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.3}
          />
        </g>
        <line
          id="Linha_107"
          data-name="Linha 107"
          x2={6.086}
          transform="translate(6.555 7.491)"
          fill="none"
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.3}
        />
        <line
          id="Linha_108"
          data-name="Linha 108"
          x2={6.086}
          transform="translate(6.555 10.768)"
          fill="none"
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.3}
        />
        <line
          id="Linha_109"
          data-name="Linha 109"
          x2={4.214}
          transform="translate(6.555 13.577)"
          fill="none"
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.3}
        />
        <line
          id="Linha_110"
          data-name="Linha 110"
          x2={2.341}
          transform="translate(6.555 16.855)"
          fill="none"
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.3}
        />
        <path
          id="Caminho_67966"
          data-name="Caminho 67966"
          d="M.468,0A.468.468,0,1,1,0,.468.468.468,0,0,1,.468,0Z"
          transform="translate(3.277 10.3)"
          fill={props.stroke as string}
          stroke={props.stroke as string}
          strokeWidth={1.3}
        />
        <path
          id="Caminho_67967"
          data-name="Caminho 67967"
          d="M.468,0A.468.468,0,1,1,0,.468.468.468,0,0,1,.468,0Z"
          transform="translate(3.277 7.023)"
          fill={props.stroke as string}
          stroke={props.stroke as string}
          strokeWidth={1.3}
        />
        <circle
          id="Elipse_63"
          data-name="Elipse 63"
          cx={0.468}
          cy={0.468}
          r={0.468}
          transform="translate(3.277 13.109)"
          fill={props.stroke as string}
          stroke={props.stroke as string}
          strokeWidth={1.3}
        />
        <circle
          id="Elipse_64"
          data-name="Elipse 64"
          cx={0.468}
          cy={0.468}
          r={0.468}
          transform="translate(3.277 16.387)"
          fill={props.stroke as string}
          stroke={props.stroke as string}
          strokeWidth={1.3}
        />
      </g>
    </g>
  </Icon>
)

export default Reports
