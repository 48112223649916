import { useState, useEffect } from 'react';
import { Flex, Heading, Box, Button, Grid, GridItem } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb';
import { api } from '../../../../api';

interface DataItem {
  seq_pagina: any;
  nom_pagina: string;
  sit_pagina: any;
  sit_registro: number;
}

interface PageData {
  pageName: string;
  situation: any;
  register: any;
  data: PageData[];
}

interface Button {
  id: any;
  label: any;
  onClick: () => void;
}

function ActionPage() {
  const navigate = useNavigate();
  const { pageName } = useParams();
  const [situation, setSituationPage] = useState(true);
  const [register, setRegister] = useState(false);
  const [filteredData, setFilteredData] = useState<PageData[]>([]);
  const [originalData, setOriginalData] = useState<PageData[]>([]);
  const [buttonsList, setButtonsList] = useState<Button[]>([]);

  const isPageActive = (situation: number) => {
    return situation === 1;
  };
  const isRegisterUnactive = (register: number) => {
    return register === 0;
  };
  
  useEffect(() => {
    const getAction = '/api/cms/manage/action/:pageName'
    api.get(getAction)
      .then(response => {
        const { data } = response;

        const pageDataArray: PageData[] = data.map((item: DataItem) => ({
          id: item.seq_pagina,
          pageName: item.nom_pagina,
          situation: Number(item.sit_pagina), 
          register: Number(item.sit_registro),
        }));
        
        setOriginalData(pageDataArray);
        setFilteredData(pageDataArray);

        const currentPageData = pageDataArray.find((pageData) => pageData.pageName === pageName);

        if (currentPageData) {
          setSituationPage(isPageActive(currentPageData.situation));
          setRegister(isRegisterUnactive(currentPageData.register));
        }
      })
      .catch(error => {
        console.error('Erro ao obter os dados do back-end:', error);
      });
  }, [pageName]);

  const inputRadio = (pageName: string | undefined, situation: boolean, register: boolean) => {
    const putAction = '/api/cms/manage/action/:pageName'
    const registerNumber = register ? 0 : 1;
    const situationNumber = situation ? 1 : 0;
  
    const data = {
      pageName: pageName,
      situation: situationNumber,
      register: registerNumber,
    };
  
    api
      .put(putAction, data)
      .then((response) => {
        //console.log('Dado enviado:', response.data);
      })
      .catch((error) => {
        console.error('Erro ao enviar os dados:', error);
      });
  };
  
  const updateButtonsListWithSortedData = () => {
    setButtonsList((prevButtonsList) =>
      prevButtonsList.slice().sort((a, b) => a.id - b.id)
    );
  
    localStorage.setItem('buttonsList', JSON.stringify(buttonsList));
  };
  
const handleSave = () => {
  inputRadio(pageName, situation, register);

  if (register) {
    const newButton = { id: originalData.length + 1, label: pageName ?? '', onClick: () => {} };
    setButtonsList((prevButtonsList) => [...prevButtonsList, newButton]); 
  } else if (!register || !situation) {
    setButtonsList((prevButtonsList) =>
      prevButtonsList.filter((button) => button.label !== pageName)
    );
  }

  updateButtonsListWithSortedData();
};

  useEffect(() => {
    const savedButtonsList = localStorage.getItem('buttonsList');
    if (savedButtonsList) {
      const parsedButtonsList = JSON.parse(savedButtonsList);
      setButtonsList(parsedButtonsList); 
    }
  }, []);
  
  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb /> 
      <Heading
        mt="44px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Ações
      </Heading>
      <Grid w="100%" gap="35px">
        <GridItem>
          <Heading
            mt="44px"
            mb="20px"
            fontWeight="600"
            fontSize="19px"
            color="#1664D7"
            w="100%"
          >
            {pageName}
          </Heading>
          <Heading
            mt="44px"
            mb="20px"
            fontWeight="600"
            fontSize="17px"
            color="#8a8a8a"
            w="100%"
          >
            Situação:
          </Heading>
          <Box>
            <label htmlFor="activePage">
              <input 
                type="radio" 
                id="activePage" 
                name="situation" 
                checked={situation}
                onChange={() => {
                  setSituationPage(true);
                }} />
              <span style={{ marginRight: '20px', marginLeft: '5px' }}>
                Ativo
              </span>
            </label>
            <label htmlFor="inactivePage">
              <input 
                type="radio" 
                id="inactivePage" 
                name="situation" 
                checked={!situation}
                onChange={() => {
                  setSituationPage(false);
                  setRegister(false)
                }} />
              <span style={{ marginLeft: '5px' }}>Inativo</span>
            </label>
          </Box>
          <Heading
            mt="44px"
            mb="20px"
            fontWeight="600"
            fontSize="17px"
            color="#8a8a8a"
            w="100%"
          >
            Adicionar esta página como forma de compartilhar suas publicações?
          </Heading>

          <Box>
            <label htmlFor="addPage">
              <input
                type="radio"
                id="addPage"
                name="AddPage"
                checked={register}
                disabled={!situation}
                onChange={() => {
                  setRegister(true);
                }}
              />
              <span style={{ marginRight: '20px', marginLeft: '5px' }}>
                Sim
              </span>
            </label>
            <label htmlFor="falseAddPage">
              <input
                type="radio"
                id="falseAddPage"
                name="AddPage"
                checked={!register}
                onChange={() => {
                  setRegister(false);
                }}
              />
              <span style={{ marginLeft: '5px' }}>Não</span>
            </label>
          </Box>
          <Box mt="44px">
            <Button type="submit" m="px" onClick={() => navigate(-1)}>
              Desistir de Alterar
            </Button>
            <Button
              type="submit"
              m="6px"
              color="#FFF"
              bgColor="#1665D8"
              _hover={{ opacity: 0.8 }}
              onClick={() => {
                handleSave();
                navigate('/cms/manage');
              }}  
            >
              Salvar Alteração
            </Button>
          </Box>
        </GridItem>
      </Grid>
    </Flex>
  )
}

export default ActionPage
