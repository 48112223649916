import {
  AiFillStar,
  AiOutlineStar,
  AiOutlineCloudDownload
} from 'react-icons/ai'

import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Image,
  Flex,
  Text,
  Icon,
  IconButton,
  Select
} from '@chakra-ui/react'

const posts = [
  {
    imageUrl: 'https://picsum.photos/200',
    name: 'Titulo',
    createdAt: '10/10/2022',
    quality: 0,
    potentialReach: '10.000 - 30.000',
    realReach: '38.000'
  }
]

function PostsTable() {
  const getRatingStars = (quality: number) => {
    const array = [0, 0, 0, 0, 0]
    return array.map((a, index) => {
      if (index <= quality) return <Icon color="#F6AB2E" as={AiFillStar} />
      else return <Icon color="#D5D5D5" as={AiOutlineStar} />
    })
  }
  return (
    <Table>
      <Thead>
        <Tr color="#9EA0A5">
          <Th color="#9EA0A5">Noticia</Th>
          <Th color="#9EA0A5">Criada</Th>
          <Th color="#9EA0A5">Qualidade</Th>
          <Th color="#9EA0A5">Alcance potencial</Th>
          <Th color="#9EA0A5">Alcance Real</Th>
        </Tr>
      </Thead>
      <Tbody>
        {posts.map((p, index) => (
          <Tr key={index}>
            <Td>
              <Flex dir="row" alignItems="center">
                <Image
                  w="50px"
                  h="50px"
                  mr="26px"
                  borderRadius="4px"
                  src={p.imageUrl}
                />

                {p.name}
              </Flex>
            </Td>
            <Td>
              <Text
                fontSize="12px"
                color="#9EA0A5"
                fontWeight={600}
                letterSpacing="1px"
              >
                {p.createdAt}
              </Text>
            </Td>
            <Td>{getRatingStars(2)}</Td>
            <Td>
              <Flex flexDirection="column">
                <Text color="#3E3F42" fontWeight={600} marginBottom="12px">
                  {p.potentialReach}
                </Text>
                <Text color="#9EA0A5">Impressões</Text>
              </Flex>
            </Td>
            <Td>
              <Flex flexDirection="column">
                <Text color="#1665D8" fontWeight={600} marginBottom="12px">
                  {p.realReach}
                </Text>
                <Text color="#9EA0A5">Impressões</Text>
              </Flex>
            </Td>

            <Td>
              <Select placeholder="Ações">
                <option value="option1">Ações</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </Td>
            <Td m="0" p="0">
              <IconButton bgColor="#fff" aria-label="Dowload">
                <Icon
                  fontSize="26px"
                  mx="16px"
                  color="#9EA0A5"
                  as={AiOutlineCloudDownload}
                />
              </IconButton>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

export default PostsTable
