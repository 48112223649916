import { useState, createContext, ReactNode, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { api } from '../../api'
import { auth } from '../../api/auth'

export const AutheticationContext = createContext({} as any)

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState('')
  const navigate = useNavigate()

  const login = async (username: string, password: string) => {
    try {
      console.log(auth, 'loginRest')
      const {
        data: { accessToken }
      } = await auth(username, password)

      localStorage.setItem('token', accessToken)
      api.defaults.headers.Authorization = 'Bearer ' + accessToken
      setToken(accessToken)
      return { error: false, accessToken }
    } catch (e: any) {
      console.log(e, 'error')
      if (e.response.status === 404) {
        return { error: true, data: 'Usuario não encontrado' }
      } else if (e.response.status === 401) {
        return { error: true, data: 'Senha incorreta' }
      } else {
        return { error: true, data: e.message }
      }
    }
  }
  const logout = () => {
    localStorage.removeItem('token')
    setToken('')
    navigate('/')
  }

  return (
    <AutheticationContext.Provider value={{ login, logout, token }}>
      {children}
    </AutheticationContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AutheticationContext)

  if (context) {
    return context
  } else throw new Error('useAuth must be used within AuthProvider')
}

export default AuthProvider
