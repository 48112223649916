import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Flex,
  Heading,
  Box,
  Button,
  Select,
  HStack,
  Input
} from '@chakra-ui/react'

import * as usersApi from '../../../../api/users'
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb'
import withAuth from '../../../../contexts/AuthContext/withAuth'
import UserTable from './UserTable'


const UsersList = () => {
  const [users, setUsers] = useState<any>([])
  const navigate = useNavigate()

  useEffect(() => {
    getUsersList()
  }, [])

  async function getUsersList() {
    const { data } = await usersApi.getUsers({ limit: 10, offset: 1 })

    setUsers(data)
  }

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="44px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Gerenciar Usuarios
      </Heading>

      {/* Search bar */}
      <Box m="40px 0" w="100%">
        <HStack spacing={5}>
          <Button
            color="#fff"
            bgColor="#1789FC"
            onClick={() => navigate('/cms/users/create')}
          >
            Criar novo usuario
          </Button>
          <Flex dir="inline" w="70%">
            <Select defaultValue="cpf" w="20%">
              <option value="cpf">CPF</option>
              <option value="nome">Nome</option>
            </Select>

            <Input ml="12px" placeholder="Digite Aqui" />
          </Flex>
        </HStack>
      </Box>

      <Box>
        <UserTable userList={users} />
      </Box>
      {/* Table */}
    </Flex>
  )
}

export default withAuth(UsersList)
