import { IconProps, Icon } from '@chakra-ui/react'
const SocialMedia = (props: IconProps) => (
  <Icon viewBox="0 0 26.286 26.286" {...props}>
    <g
      id="Grupo_23029"
      data-name="Grupo 23029"
      transform="translate(-778.4 -1169.4)"
    >
      <path
        id="Caminho_67956"
        data-name="Caminho 67956"
        d="M435.711,232.559a10.736,10.736,0,1,0-3.152,3.152l4.886,1.734Z"
        transform="translate(366.642 954)"
        fill="none"
        stroke={props.stroke as string}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
      />
      <path
        id="Caminho_67957"
        data-name="Caminho 67957"
        d="M421.826,254.16a9.091,9.091,0,0,1-10.677.551L407,256.183l1.472-4.149a9.091,9.091,0,0,1,.551-10.677"
        transform="translate(372 938.903)"
        fill="none"
        stroke={props.stroke as string}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
      />
      <g
        id="Grupo_23011"
        data-name="Grupo 23011"
        transform="translate(787.092 1173.917)"
      >
        <path
          id="Caminho_67958"
          data-name="Caminho 67958"
          d="M427,232.688h2.023a9.884,9.884,0,0,0,3.229-3.918,5.9,5.9,0,0,0,.413-2.961s1.214-.809,1.618,1.618a8.468,8.468,0,0,1-.4,3.642"
          transform="translate(-427 -225.68)"
          fill="none"
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.2}
        />
        <path
          id="Caminho_67959"
          data-name="Caminho 67959"
          d="M452.4,243a.812.812,0,0,1,.809.809h0a.812.812,0,0,1-.809.809H452"
          transform="translate(-441.885 -235.992)"
          fill="none"
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.2}
        />
        <path
          id="Caminho_67960"
          data-name="Caminho 67960"
          d="M451.4,247a.812.812,0,0,1,.809.809h0a.812.812,0,0,1-.809.809H451"
          transform="translate(-441.289 -238.374)"
          fill="none"
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.2}
        />
        <path
          id="Caminho_67961"
          data-name="Caminho 67961"
          d="M439.688,251a.812.812,0,0,1,.809.809h0a.812.812,0,0,1-.809.809h-2.644a14.161,14.161,0,0,1-2.777-.275l-.648-.129H432"
          transform="translate(-429.977 -240.755)"
          fill="none"
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.2}
        />
        <path
          id="Caminho_67962"
          data-name="Caminho 67962"
          d="M444,239h3.642a.812.812,0,0,1,.809.809h0a.812.812,0,0,1-.809.809h-.4"
          transform="translate(-437.121 -233.611)"
          fill="none"
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.2}
        />
      </g>
    </g>
  </Icon>
)

export default SocialMedia
