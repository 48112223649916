import { Grid, GridItem } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'

import AppRoutes from '../../routes'
import Header from '../Header'
import Sidebar from '../Sidebar'

function LoggedInArea() {
  return (
    <Grid
      templateRows="1fr 11fr"
      templateColumns="20% 80%"
      width="100%"
      height="100hv"
    >
      <GridItem colSpan={1} rowSpan={2}>
        <Sidebar />
      </GridItem>
      <GridItem colSpan={1} rowSpan={1}>
        <Header />
      </GridItem>
      <GridItem bgColor="#fafafa">
        <Outlet />
      </GridItem>{' '}
    </Grid>
  )
}

export default LoggedInArea
