import { useState } from 'react'

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text
} from '@chakra-ui/react'

import { registerComunication } from '../../../api/comunication'
import SettingsBreadcrumb from '../../../components/SettingsBreadcrumb'

const Comunication = () => {
  const [typeOfService, setTypeOfService] = useState('none')
  const [formValues, setFormValues] = useState({
    serverAddress: {
      value: '',
      error: false
    },
    serverUser: {
      value: '',
      error: false
    },
    serverPort: {
      value: '',
      error: false
    },
    serverPassword: {
      value: '',
      error: false
    },
    senderIdentity: {
      value: '',
      error: false
    }
  })

  const handleFieldChange = (field: string, value: string) =>
    setFormValues({
      ...formValues,
      [field]: {
        error: false,
        value
      }
    })

  const handleSubmit = async () => {
    const params = {
      dscUsuario: formValues.serverUser.value,
      dscServPorta: formValues.serverPort.value,
      dscServEndereco: formValues.serverAddress.value,
      dscSenha: formValues.serverPassword.value,
      dscRemetente: formValues.senderIdentity.value
    }
    await registerComunication(params)
  }

  return (
    <>
      <Flex
        color="#6F6F6F"
        p="40px"
        bgColor="#fafafa"
        direction="column"
        w="90%"
      >
        <SettingsBreadcrumb />
        <Heading mt="44px" fontWeight="600" fontSize="21px" w="100%">
          Serviço de Comunicação
        </Heading>
        <Text my="1rem">Tipo de servidor</Text>
        <Stack spacing={6}>
          <RadioGroup
            defaultChecked={true}
            value={typeOfService}
            onChange={(value) => setTypeOfService(value)}
          >
            <HStack spacing={3}>
              <Radio value="none">
                <Text>Não usamos</Text>
              </Radio>
              <Radio value="smtp">
                <Text>SMTP</Text>
              </Radio>
            </HStack>
          </RadioGroup>

          {typeOfService === 'smtp' && (
            <>
              <HStack spacing={6}>
                <FormControl>
                  <FormLabel mb="8px">Endereço de servidor</FormLabel>
                  <Input
                    aria-label="serverAddress"
                    value={formValues.serverAddress.value}
                    onChange={(e) =>
                      handleFieldChange('serverAddress', e.target.value)
                    }
                    size="sm"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel mb="8px">Porta do servidor</FormLabel>
                  <Input
                    aria-label="serverPort"
                    value={formValues.serverPort.value}
                    onChange={(e) =>
                      handleFieldChange('serverPort', e.target.value)
                    }
                    size="sm"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel mb="8px">Usuário para autenticar</FormLabel>
                  <Input
                    aria-label="serverUser"
                    value={formValues.serverUser.value}
                    onChange={(e) =>
                      handleFieldChange('serverUser', e.target.value)
                    }
                    size="sm"
                  />
                </FormControl>
              </HStack>
              <HStack spacing={6}>
                <FormControl>
                  <FormLabel mb="8px">Senha do usuário autenticar</FormLabel>
                  <Input
                    aria-label="serverPassword"
                    value={formValues.serverPassword.value}
                    onChange={(e) =>
                      handleFieldChange('serverPassword', e.target.value)
                    }
                    size="sm"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel mb="8px">Identificação do remetente</FormLabel>
                  <Input
                    aria-label="senderIdentity"
                    value={formValues.senderIdentity.value}
                    onChange={(e) =>
                      handleFieldChange('senderIdentity', e.target.value)
                    }
                    size="sm"
                  />
                </FormControl>
              </HStack>
              <HStack align="flex-end" justifyContent="flex-end" w="100%">
                <Button fontSize="12px" fontWeight="700">
                  Desistir
                </Button>{' '}
                <Button
                  color="#FFF"
                  bgColor="#1665D8"
                  fontSize="12px"
                  fontWeight="700"
                  onClick={handleSubmit}
                >
                  Salvar Alteração
                </Button>
              </HStack>
            </>
          )}
        </Stack>
      </Flex>
    </>
  )
}

export default Comunication
