import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb'
import {ImageDropzone} from '../Banner/imageDropzone'
import { useLocation } from 'react-router-dom';
import { api } from '../../../../api'
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../contexts/AuthContext';

import {
  Heading,
  Flex,
  GridItem,
  Textarea,
  Input,
  Box,
  Button
} from '@chakra-ui/react'
import { FileWithPath } from 'react-dropzone'

export interface bannerInterface {
  id: number;
  title: string;
  image: string;
  description: string;
}

function BannerEdit() {
  const { id } = useParams();
  const navigate = useNavigate()
  const location = useLocation();
  const { state } = location;
  const bannerData = state?.banner || {};
  const [title, setTitle] = useState(bannerData.title || ''); 
  const [isImageSelected, setIsImageSelected] = useState(!!bannerData.image); 
  const [description, setdescription] = useState(bannerData.description || '');
  const [image, setImage] = useState(bannerData.image || '');
  const [banners, setBanners] = useState([]);
  const [imageBase64, setImageBase64] = useState('');

  const { token } = useAuth();
  

  interface Banner {
    seq_conteúdo: number;
    nom_publicacao: string;
    bin_publicacao: any;
    dsc_publicacao: string;
  }  

  useEffect(() => {
    console.log('State:', state);
  }, [state]);
  

  const handleTitleChange = (event: any) => {
    setTitle(event.target.value)
  }

  const handleEditorChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setdescription(event.target.value)
  }
  const handleImageSelected = async (selected: FileWithPath | null) => {
    if (selected) {
      const base64Image = await convertBase64(selected);
      setIsImageSelected(true);
      setImageBase64(base64Image); 
      setImage(base64Image);
      //console.log('base64Image', base64Image)
    }
  };

  const convertBase64 = async (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
  
      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };
  
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const inputBanner = (title: string, base64Image: string, description: string) => {
    const putBanner = `/api/cms/content/banner/${id}`;
  
    const data = {
      title: title,
      image: base64Image,
      description: description,
    };
  
    api
      .put(putBanner, data)
      .then((response) => {
        console.log('Dado enviado:', data);
        console.log('imageBase64:', base64Image);
      })
      .catch((error) => {
        console.error('Erro ao enviar os dados:', error);
      });
  };

  const handleSubmit = () => {
    inputBanner(title, image, description);
  }

  return (
    <Flex h="100%" p="40px" bgColor="#fafafa" direction="column">
      <SettingsBreadcrumb />
      <Heading fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%" mt={3}>
        Editar Banner
      </Heading>
      <Heading
        pt="24px"
        pb="32px"
        fontWeight="600"
        fontSize="20px"
        color="#1664D7"
      >
        {' '}
        Adicione um Título
      </Heading>
      <Input
        type="text"
        mb={30}
        value={title}
        placeholder={'Digite o Título'}
        maxLength={50}
        onChange={handleTitleChange}
      />
      <Box>
        <ImageDropzone 
          onImageSelected={(selected: FileWithPath | null) => handleImageSelected(selected)}
          base64={image} 
        />
      </Box>
      <Heading
        pt="24px"
        pb="32px"
        fontWeight="600"
        fontSize="20px"
        color="#1664D7"
      >
        {' '}
        Adicione uma descrição da imagem
      </Heading>
      <Textarea
        height={100}
        value={description}
        onChange={handleEditorChange}
        required
      ></Textarea>
      <Flex mt="35px" justifyContent="end">
        <Button type="submit" m="6px" onClick={() => navigate(-1)}>
          Cancelar
        </Button>
        <Button
          type="submit"
          m="6px"
          color="#FFF"
          bgColor="#1665D8"
          _hover={{ opacity: 0.8 }}
          onClick={() => {
            handleSubmit()
            navigate('/cms/content')
          }}
          disabled={!description}
        >
          Salvar Alteração
        </Button>
      </Flex>
    </Flex>
  )
}

export default BannerEdit
