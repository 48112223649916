import { api } from '.'

export interface ApiComunication {
  sitServidor?: string
  dscRemetente: string
  dscServEndereco: string
  dscSenha: string
  dscServPorta: string
  dscUsuario: string
}

export const getComunication = async () => {
  return await api.get('/api/comunication')
}

export const registerComunication = async (comunication: ApiComunication) => {
  return await api.post('api/comunication', comunication)
}

export const updateComunication = async (
  comunicationId: string,
  comunication: ApiComunication
) => {
  return api.put(`api/comunication/${comunicationId}`, comunication)
}
