import { useState } from 'react'

import { Heading, Flex, SimpleGrid, Button } from '@chakra-ui/react'

import ModalEditor from '../../components/ModalEditor'
import withAuth from '../../contexts/AuthContext/withAuth'
// import VisualizeContent from './components/VisualizeContent'
import WhatToPublish from './components/WhatToPublish'
import WhereToPublish from './components/WhereToPublish'
import SettingsBreadcrumb from '../../components/SettingsBreadcrumb'

function PublishContent() {
  const [modalEditor, setModalEditor] = useState({
    text: false,
    image: false,
    video: false
  })

  const handleInsertModalOpen = (type: string) => {
    console.log(type)
    setModalEditor({ ...modalEditor, [type]: true })
  }

  const handleInsertModalClose = (type: string) => {
    setModalEditor({ ...modalEditor, [type]: false })
  }
  return (
    <Flex 
      position="absolute"
      h="100%" 
      p="40px" 
      bgColor="#fafafa" 
      direction="column"
      >
      <SettingsBreadcrumb /> 
      <Heading fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%" mt={5}>
        Publicar conteúdo
      </Heading>
      <Heading
        pt="24px"
        pb="32px"
        fontWeight="600"
        fontSize="21px"
        color="#1664D7"
      >
        {' '}
        O que você quer publicar agora?
      </Heading>
      {/* <WhatToPublish
        // onInsertImageClick={() => handleInsertModalOpen('image')}
        onInsertTextClick={() => handleInsertModalOpen('text')}
        onInsertVideoClick={() => handleInsertModalOpen('video')}
      /> */}
      <WhereToPublish />
      {/* <VisualizeContent /> */}
      {/* <SimpleGrid gridTemplateColumns="repeat(2, 125px)" gap={50}>
        <Button fontWeight="400" color="#fff" bgColor="#1789FC">
          Agendar
        </Button>

        <Button fontWeight="400" color="#fff" bgColor="#1789FC">
          Publicar
        </Button>
      </SimpleGrid> */}
      <ModalEditor
        open={modalEditor.text}
        onClose={() => handleInsertModalClose('text')}
      />
    </Flex>
  )
}
export default withAuth(PublishContent)
