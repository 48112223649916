import { api } from './'

interface IUserParams {
  dscCpf: string
  dscEmail: string
  nomUsuario: string
  codPerfilAcesso: string
  dscDddCelular: string
  dscNumCelular: string
}

export const getUsers = async (
  pagination: { limit: number; offset: number },
  dscCpf?: string,
  nomUsuario?: string
) => {
  return await api.get('/api/users', {
    params: {
      ...pagination,
      dscCpf,
      nomUsuario
    }
  })
}

export const getUser = async (userId: string) => {
  return await api.get(`/api/users/${userId}`)
}

export const createUser = async (params: IUserParams) => {
  return await api.post('/api/users', params)
}

export const updateUser = async (userId: string, params: IUserParams) => {
  return await api.put(`/api/users/${userId}`, params)
}
