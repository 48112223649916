import { FaUsers, FaBookOpen, FaRegEnvelope } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { Grid, GridItem } from '@chakra-ui/react'

import { SettingsCard } from '../../../../components/SettingsCard'

const GridSettings = () => {
  const navigate = useNavigate()

  return (
    <Grid templateColumns="3fr 3fr 3fr 3fr" w="105%" gap={10}>
      <GridItem>
        <SettingsCard
          buttonText="Acessar"
          onClick={() => navigate('/cms/users')}
          icon={FaUsers}
          cardTitle="Gerenciar Usuarios"
        />
      </GridItem>
      <GridItem>
        <SettingsCard
          buttonText="Acessar"
          onClick={() => navigate('/cms/manage')}
          icon={FaBookOpen}
          cardTitle="Gerenciar Páginas"
        />
      </GridItem>
      <GridItem>
        <SettingsCard
          buttonText="Acessar"
          onClick={() => navigate('/cms/comunication')}
          icon={FaRegEnvelope}
          cardTitle="Serviço de Comunicação"
        />
      </GridItem>
    </Grid>
  )
}

export default GridSettings
