import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIMask } from 'react-imask'

import {
  Container,
  Box,
  Img,
  Input,
  Stack,
  FormControl,
  InputGroup,
  FormHelperText,
  FormErrorMessage,
  Link,
  Button,
  useToast
} from '@chakra-ui/react'

import logo from '../../assets/logo.svg'
import { useAuth } from '../../contexts/AuthContext'

const Login = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState({
    cpf: {
      value: '',
      error: false
    },
    password: {
      value: '',
      error: false
    }
  })
  const { login } = useAuth()

  const handleFieldChange = (field: string, value: string) =>
    setFormValues({
      ...formValues,
      [field]: {
        error: false,
        value
      }
    })

    const { ref: cpfRef } = useIMask(
      { mask: '000.000.000-00' },
      {
        onAccept: (value) => handleFieldChange('cpf', value)
      }
    )

  const isValidateForm = () => {
    let isValid = true
    if (!formValues.cpf.value) {
      setFormValues({
        ...formValues,
        cpf: { ...formValues.cpf, error: true }
      })
      isValid = false
    }

    if (!formValues.password.value) {
      setFormValues({
        ...formValues,
        password: { ...formValues.password, error: true }
      })
      isValid = false
    }

    return isValid
  }

  const handleSubmit = async (e: any) => {
    const cpf = formValues.cpf.value.replace(/\.|-/gm,'')
    const password = formValues.password.value
    e.preventDefault()

    if (isValidateForm()) {
      const res = await login(cpf, password)
      console.log(res)
      if (!res.error) {
        navigate('/cms')
      } else {
        toast({
          title: res.data,
          description: '',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    }
  }

  return (
    <Container
      overflowX="auto"
      color="#000"
      bgColor="#EDEDED"
      display="flex"
      alignItems="center"
      justifyContent="center"
      w="100%"
      maxWidth="100%"
      maxHeight="100%"
      height="100vh"
      p="0"
      m="0"
    >
      <Box
        width="30%"
        h="60%"
        boxShadow="3px 3px 3px rgba(0,0,0, .2)"
        bgColor="#fff"
        borderRadius="4px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        p="0 40px"
      >
        <form onSubmit={handleSubmit}>
          <Stack spacing={4} w="100%">
            <Img src={logo} m="25px" />
            <FormControl isInvalid={formValues.cpf.error}>
              <InputGroup>
              <Input
                maxLength={14}
                placeholder='CPF'
                aria-label="cpf"
                id="cpf"
                arai-label="cpf"
                ref={cpfRef}
                value={formValues.cpf.value}
                onChange={(e) =>
                  handleFieldChange('cpf', e.target.value.trim())
                }
              />
              </InputGroup>
              {formValues.cpf.error && (
                <FormErrorMessage>Campo Obrigatório</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={formValues.password.error}>
              <InputGroup>
                <Input
                  type={'password'}
                  placeholder="Senha"
                  value={formValues.password.value}
                  onChange={(e) =>
                    handleFieldChange('password', e.target.value.trim())
                  }
                />
              </InputGroup>
              {formValues.password.error && (
                <FormErrorMessage>Campo Obrigatório</FormErrorMessage>
              )}
              <FormHelperText textAlign="right">
                <Link>Esqueceu a senha?</Link>
              </FormHelperText>
            </FormControl>
            <Button
              borderRadius={0}
              variant="solid"
              colorScheme="teal"
              width="full"
              type="submit"
            >
              Acessar
            </Button>
          </Stack>
        </form>
      </Box>
    </Container>
  )
}

export default Login