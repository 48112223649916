import { Heading, Button, Icon, Stack } from '@chakra-ui/react'

export interface SettingsCardProps {
  icon: any
  buttonText: string
  onClick: () => void
  cardTitle: string
}
const SettingsCard = ({
  icon,
  buttonText,
  onClick,
  cardTitle
}: SettingsCardProps) => {
  return (
    <Stack
      alignItems="center"
      flexDirection="column"
      p="22px"
      w="100%"
      color="#3E3F42"
      border="1px solid #EAEDF3"
      bgColor="#fff"
      borderRadius="4px"
      spacing="30px"
    >
      <Icon as={icon} fontSize="36px" color="#1789FC" />
      <Heading fontSize="18px">{cardTitle}</Heading>
      <Button
        color="#fff"
        bgColor="#1789FC"
        w="100%"
        onClick={onClick}
        m="10px 0"
      >
        {buttonText}
      </Button>
    </Stack>
  )
}

export default SettingsCard
