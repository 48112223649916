import axios from 'axios'

console.log(window.location, 'window.location.hostname')

export const api = axios.create({
  baseURL: 'https://cms.acto.net.br/back',
    // window.location.hostname === 'localhost'
    //   ? 'http://localhost:1333/'
    //   : `https://cms.acto.net.br/back`,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*'
  }
})
