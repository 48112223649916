import React from 'react'

import { ChakraProvider } from '@chakra-ui/react'
import './index.css'

import AuthProvider from './contexts/AuthContext'
import Pages from './pages'
import theme from './utils/theme'

export default function App() {
  return (
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <Pages />
      </ChakraProvider>
    </AuthProvider>
  )
}
