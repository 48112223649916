import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import LoggedInArea from '../components/LoggedInArea'
import Calendar from '../pages/Calendar'
import CreatePassword from '../pages/CreatePassword'
import Library from '../pages/Library'
import Login from '../pages/Login'
import Profile from '../pages/Profile'
import PublishContent from '../pages/PublishContent'
import Reports from '../pages/Reports'
import Settings from '../pages/Settings'
import FormCreateNewUser from '../pages/Settings/Users/FormCreateNewUser'
import FormUpdateUser from '../pages/Settings/Users/FormUpdateUser'
import UsersList from '../pages/Settings/Users/List'
import NewUser from '../pages/Settings/Users/New'
import SocialMedia from '../pages/SocialMedia'
import ManagePages from '../pages/Settings/SettingPage'
import ActionPage from '../pages/Settings/SettingPage/Actions/ActionPage'
import AddPage from '../pages/Settings/SettingPage/IncludedPages/addPage'
import Comunication from '../pages/Settings/Comunication'
import CreateText from '../pages/PublishContent/components/WhatToPublish/createText'
import CreateVideo from '../pages/PublishContent/components/WhatToPublish/createVideo'
import BannerEdit from '../pages/PublishContent/components/PreviewCard/bannerEdit'
import Banner from '../pages/PublishContent/components/Banner/banner'

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route index element={<Login />} path="/login" />
      <Route element={<CreatePassword />} path="/create-password" />

      <Route element={<LoggedInArea />} path={'/cms'}>
        <Route index element={<PublishContent />} path="content" />
        <Route element={<CreateText />} path="content/createText" />
        <Route element={<BannerEdit />} path="content/bannerEdit/:id" />
        <Route element={<Banner />} path="content/banner" />
        <Route element={<CreateVideo />} path="content/createVideo" />
        <Route element={<Profile />} path="profile" />
        <Route element={<SocialMedia />} path="social-media" />
        <Route element={<Calendar />} path="calendar" />
        <Route element={<Library />} path="library" />
        <Route element={<Reports />} path="reports" />
        <Route element={<Settings />} path="settings" />
        <Route element={<ManagePages />} path="manage" />
        <Route element={<AddPage />} path="manage/addPage" />
        <Route
          element={<ActionPage />}
          path="manage/ActionPage/:pageName"
        />

        <Route element={<Comunication />} path="comunication" />
        <Route element={<ManagePages />} path="manage" />
        <Route element={<AddPage />} path="manage/add" />
        <Route element={<ActionPage />} path="manage/action/:pageName" />

        <Route element={<UsersList />} path="users" />
        <Route element={<NewUser />} path="users/new" />
        <Route element={<FormCreateNewUser />} path="users/create" />
        <Route element={<FormUpdateUser />} path="users/:userId" />
      </Route>
    </Routes>
  )
}

export default AppRoutes
