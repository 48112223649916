  import React, { Component, useCallback, useState } from "react";
  import Dropzone, { FileWithPath, FileRejection, useDropzone } from "react-dropzone";
import { useLocation } from "react-router-dom";
  import styled from "styled-components";

  interface ImageDropzoneProps {
    onImageSelected: (selected: File | null) => void;
    base64: string
  }

  const DropContainer = styled.div<{ isDragActive: boolean; isDragReject: boolean }>`
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${(props) =>
      props.isDragActive ? "#e0f7fd" : props.isDragReject ? "#ffd7d7" : "white"};
  `;

  const ImageContainer = styled.div`
    position: relative;
    display: inline-block;
  `;

  const UploadedImage = styled.img`
    max-width: 100%;
    max-height: 200px;
  `;

  const ChangeImageButton = styled.button`
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border: none;
    padding: 5px;
    cursor: pointer;
  `;

  const UploadMessage = styled.p`
    text-align: center;
    font-size: 16px;
    color: #999999;
  `;

  const ImageDropzone: React.FC<ImageDropzoneProps> = ({ onImageSelected, base64 }) => {
    const location = useLocation();
    const { state } = location;
    const defaultImage = state?.image || null;
    const [selectedImage, setSelectedImage] = useState<string | null>(defaultImage);
    const [previewMode, setPreviewMode] = useState(false);
    const [previewImageSrc, setPreviewImageSrc] = useState('');
    const [isImageSelected, setIsImageSelected] = useState(false);

    const convertBase64 = async (file: File): Promise<string> => {
      return new Promise<string>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
  
        fileReader.onload = () => {
          resolve(fileReader.result as string);
        };
  
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };
  
    const handleDrop = useCallback(async (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 1) {
        setSelectedImage(null);
        console.log("Arquivo aceito:", acceptedFiles[0]);
  
        const base64 = await convertBase64(acceptedFiles[0]);
        setSelectedImage(base64);
        setPreviewMode(true);
        setPreviewImageSrc(base64);
        console.log('bsse64:', base64)
 
        onImageSelected(acceptedFiles[0]);
      } else {
        setSelectedImage(null);
        setPreviewMode(false);
        setPreviewImageSrc('');
      }
    }, [onImageSelected]);
  
    const handleChangeImage = () => {
      console.log("Alterar imagem clicado");
      setSelectedImage(null);
      onImageSelected(null);
      isImageSelected==false
    };
  
    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
      accept: {
        'image/png': ['.png'],
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/gif': ['.gif'],
      },
      onDrop: handleDrop,
    });
  
    return (
      <DropContainer {...getRootProps({ isDragActive, isDragReject })}>
        <input {...getInputProps()}/>
        {selectedImage ? (
          <ImageContainer>
            <UploadedImage src={selectedImage} /> 
            <ChangeImageButton onClick={handleChangeImage}>
              Alterar imagem
            </ChangeImageButton>
          </ImageContainer>
        ) : isDragActive ? (
          <UploadMessage>Arraste os arquivos aqui...</UploadMessage>
        ) : (
          <UploadMessage>
            Arraste fotos e gifs aqui, ou clique para selecionar os arquivos.
          </UploadMessage>
        )}
      </DropContainer>
    );
  };
  
  export {ImageDropzone};

  
