import * as React from 'react'

import { Flex, Box, Img, Text } from '@chakra-ui/react'

import logo from '../../assets/logo.svg'
import logoPref from '../../assets/logoPref.svg'

export interface Props {
  icon: React.ReactNode
  plataformTitle: React.ReactNode
  companyLogo: React.ReactNode
}

function Header() {
  return (
    <Flex
      bgColor="#fff"
      px="30px"
      w="100%"
      height="100%"
      maxHeight="76px"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px solid #EAEDF3"
    >
      <Flex flexDir="row">
        <Box borderRight="1px" borderColor="#D5D5D5">
          <Img src={logo} />
        </Box>
        <Box>
          <Text fontSize={18} fontWeight={900}>
            Plataforma De Publicação
          </Text>
        </Box>
      </Flex>
      <Box>
        <Img src={logoPref} />
      </Box>
    </Flex>
  )
}

export default Header
