import { MdEdit } from 'react-icons/md'

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Icon,
  IconButton
} from '@chakra-ui/react'

type User = {
  nomUsuario: string
  dscCpf: string
  ativo: boolean
}

interface UserTableProps {
  userList: User[]
}

const UserTable = ({ userList }: UserTableProps) => {
  const renderRows = () =>
    userList.map((u) => (
      <Tr key={u.dscCpf}>
        <Td>{u.dscCpf}</Td>
        <Td>{u.nomUsuario}</Td>
        <Td>{u.ativo ? 'Ativo' : 'Inativo'}</Td>
        <Td>
          <IconButton aria-label="Botão Editar" bgColor="#fff">
            <Icon as={MdEdit} fontSize="24px" color="#1789FC" />
          </IconButton>
        </Td>
      </Tr>
    ))

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>CPF</Th>
            <Th>Nome</Th>
            <Th>Situação</Th>
            <Th>Editar</Th>
          </Tr>
        </Thead>
        <Tbody>{renderRows()}</Tbody>
      </Table>
    </TableContainer>
  )
}

export default UserTable
